<script setup lang="ts">
import IconEye from '~icons/mdi/eye'
import IconEyeOff from '~icons/mdi/eye-off'

definePageMeta({
  layout: 'auth',
  title: 'login.label',
  middleware: 'auth',
})

const { t } = useI18n()
const store = useUserStore()

const { countryLocalePath } = useCountry()
const route = useRoute()

const countryCookie = useCookie('vetmazing-country')
const { countryByGuess } = useCountry()

if (!countryCookie.value) {
  countryCookie.value = unref(countryByGuess)
}

// data
const isFormValid = ref(false)
const isLoading = ref(false)
const username = ref()
const password = ref()

// validation
const emailRules = [
  useRequiredRule,
  (value: string) => useMaxLengthRule(value, 127),
  useEmailRule,
]
const passwordRules = [
  useRequiredRule,
]
const passwordVisible = ref(false)

const errorMessages: {
  [key: string]: Array<string>
} = reactive({
  email: [],
  password: [],
})

async function submit() {
  if (!isFormValid.value)
    return
  isLoading.value = true

  const response = await store.login(username.value, password.value)
  if (response.data && response.data.errors) {
    for (const key of Object.keys(response.data.errors))
      errorMessages[key] = [t(`errors.code.${response.data.errors[key]}`)]
  }
  else {
    const { from } = route.query
    const targetPath = from ? String(from) : '/welcome'
    await navigateTo(countryLocalePath (targetPath.replace(/^(\/\w{2}){0,2}\//, '/')))
  }

  isLoading.value = false
}
</script>

<template>
  <div class="d-flex justify-center mt-12">
    <div v-if="store.isAuthenticated" class="container--center text-center">
      <v-progress-circular indeterminate color="heiland-light-blue" />
    </div>

    <v-card v-else elevation="0" class="rounded-xl test--korv pa-8 d-flex flex-column justify-center" width="820">
      <div class="mb-6 mt-4 text-h3 text-center">
        {{ $t('login.label') }}
      </div>

      <v-row no-gutters align="center" justify="center" class="mb-8 ma-0 ga-1">
        <h2>
          {{ $t('login.createNewAccount') }}
        </h2>
        <NuxtLink :to="countryLocalePath('/register')">
          <h2>
            {{ $t('registration.header') }}
          </h2>
        </NuxtLink>
      </v-row>

      <v-form v-model:model-value="isFormValid" class="d-flex justify-center mb-4" @submit.prevent="submit">
        <v-col cols="12" md="8">
          <h2 class="mb-4 font-weight-bold">
            {{ $t('login.credentials') }}
          </h2>

          <v-label for="username" :text="$t('email')" />
          <v-text-field-primary
            id="username"
            v-model="username"
            :rules="emailRules"
            :error-messages="errorMessages.email"
            inputmode="email"
            name="username"
            autocomplete="username"
            data-test-id="login-username-input"
          />

          <v-label for="current-password" :text="$t('password')" />
          <v-text-field-primary
            id="current-password"
            v-model="password"
            :rules="passwordRules"
            :error-messages="errorMessages.password"
            :append-inner-icon="passwordVisible ? IconEyeOff : IconEye"
            :type="passwordVisible ? 'text' : 'password'"
            name="current-password"
            autocomplete="current-password"
            data-test-id="login-password-input"
            @click:append-inner="() => (passwordVisible = !passwordVisible)"
          />

          <nuxt-link :to="countryLocalePath('/forgot-password')">
            {{ $t('forgotPassword.label') }}
          </nuxt-link>

          <div class="d-flex justify-center align-center">
            <v-btn-primary
              color="green-500" :loading="isLoading" type="submit" :text="$t('login.label')"
              class="font-weight-bold mt-8"
              data-test-id="login-submit-button"
            />
          </div>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>
